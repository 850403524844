import auth from "../../api/group/auth";
import router from "../../router/index";
// initial state
const state = {
    username: '',   //当前钱包地址
    bidRatio: null, //竞价比例 最后成交价 + 最后成交价 * bidRatio/1000
    viewDetails: false,
    list: [],
    auctionId: null,
    userInfo: null,
    signCode: null,
    auditInfo: null,
    isJoin: false, //是否已经申请加入
    isContribute: false,
    isMe: false,
    configCon: null,
    logoutUrl:""
}
// getters
const getters = {}

// actions
const actions = {
    async login({commit, dispatch}, {address, code, sign}) {
        let res = await auth().login({
            address, code, sign
        });
        if (res.data.code == 200) {
            //登录成功
            let curTime = new Date().getTime(); //获取当前时间的
            let data = {
                "token": res.data.data.token, //获取用户信息用的token
                "curTime": curTime, ///存一个时间用于判断
            };
            commit('setData', data);
            await dispatch("info");
        }
        return res;
    },
    async info({commit, state}) {
        let res = await auth(state.token).userInfo();
        if (res.data.code == 200) {
            commit("setUserInfo", {userInfo: res.data.data});
        }
        return res;
    },
    async configInfo({commit}) {
        let res = await auth().commonConfig();
        if (res.data.code == 200) {
            res.data.data.contract_address = "0x907c3671524Af69A24BB59C8cdF95DdeCE3538ee";
            commit("setData", {configCon: res.data.data});
        }
        return res;
    },
};

// mutations
const mutations = {
    setUserInfo(state, {userInfo}) {
        Object.assign(state, {
            userInfo,
        });
    },
    setData(state, obj) {
        Object.assign(state, obj);
    },
    logout(state) {
        state.username = "";
        state.userInfo = null;
        state.signCode = null;
        state.logoutUrl = router.history._startLocation;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
