<template>
    <div class="header" :class="{'noFixed':drawer}">
        <div class="header-content">
            <div class="header-left">
                <div class="logo" @click="toHome">
                    <img src="../assets/logo.png" class="img">
                </div>
            </div>
            <div class="haeder-right">
                <p v-for="(item,index) in headerList" :key="index"
                   @click="toPath(item)"
                   class="nav-item">
                    {{item.title}}
                </p>
                <div class="more" @click="opendrawer">
                    <i class="iconfont icon-mianbaoxie"></i>
                    <p class="notify" v-if="status==1&&isJoin==0&&!isContribute || status==1&&isJoin!=0&&!isMe">1</p>
                </div>
                <p class="link-wallet" v-if="!userInfo" @click="connect">
                    <i class="iconfont icon-qianbao"></i>
                </p>
                <el-dropdown v-else trigger="click"
                             @command="handleCommand" :show-timeout="0"
                >
                    <p class="user-wallet">
                        {{walletSub(username, 4)}}
                    </p>
                    <el-dropdown-menu
                            class="user-menu"
                            slot="dropdown">
                        <el-dropdown-item
                                v-for="(items,keys) in walletList"
                                :key="keys"
                                :command="items">
                            <i class="iconfont" :class="items.icon"></i>
                            <span>{{items.tit}}</span>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>

            </div>
        </div>
        <el-drawer
                :visible.sync="drawer"
                :before-close="handleClose">
            <div class="avatar-container">
                <div class="logo">
                    <img src="../assets/logo.png" class="image">
                </div>
                <div class="link-wallet" v-if="!userInfo" @click="connect">
                    <i class="iconfont icon-qianbao"></i>
                    <span>Connect Wallet</span>
                </div>
                <div class="link-wallet" v-else>
                    {{walletSub(username, 7)}}
                </div>
                <div class="line"></div>
            </div>
            <ul class="nav-wrap">
                <li v-for="(item,index) in rightList" :key="index"
                    class="nav-item"
                    @click="toPath(item)"
                >
                    <p class="border"></p>
                    <span>{{item.title}}</span>
                    <p class="prompt"
                       v-if="item.path=='/Apply'&&status==1&&isJoin==0&&!isContribute || item.title=='me'&&status==1&&isJoin!=0&&!isMe"></p>
                </li>
            </ul>
        </el-drawer>
    </div>
</template>

<script>
    import {Init} from "../plugins/web3";
    import {mapState, mapMutations, mapActions} from "vuex";
    import avatar from "../ABI/avatar.json";

    export default {
        name: "myheader",
        data() {
            return {
                drawer: false,
                headerList: [{
                    title: "contribute",
                    path: "/Apply"
                }, {
                    title: "apply",
                    path: "/Proposal"
                }, {
                    title: "governance",
                    path: "/governance"
                }, {
                    title: "projects",
                    path: "/vote"
                }],
                rightList: [{
                    title: "contribute",
                    path: "/Apply"
                }, {
                    title: "apply",
                    path: "/Proposal"
                }, {
                    title: "governance",
                    path: "/governance"
                }, {
                    title: "projects",
                    path: "/vote"
                }, {
                    title: "me",
                    path: "/Usecenter"
                }, {
                    title: "about",
                    link: true,
                    path: "http://avatardao.gitbook.io"
                }],
                web3: null,
                walletList: [{
                    tit: "Copy address",
                    icon: "icon-fuzhi-copy"
                }, {
                    tit: "Disconnect Wallet",
                    icon: "icon-qianbao1"
                }]
            }
        },
        computed: {
            ...mapState('user', ['userInfo', 'username', 'signCode', 'auditInfo', 'isJoin', 'isContribute', 'isMe', 'logoutUrl']),
            status() {
                if (this.userInfo && this.userInfo.userinfo) {
                    return this.userInfo.userinfo.status;
                }
                return 0;
            }
        },
        mounted() {
            if (this.userInfo) {
                this.getisJsin();
                this.info();
            }
        },
        methods: {
            ...mapMutations('user', ['setData', 'logout']),
            ...mapActions('user', ['login', 'info']),
            handleCommand(command) {
                if (command.icon == 'icon-fuzhi-copy') {
                    this.copyUrl(this.username, 'Copy successfully');
                } else {
                    this.logout();
                    this.$message({
                        message: "exit successfully",
                        type: "success",
                    });
                }
            },
            opendrawer() {
                this.drawer = true;
                // let nowTime = new Date().getTime();
                // if (nowTime > this.auditInfo.endTime * 1000) {
                //     this.rightList.unshift({
                //         title: "结算收益",
                //         path: "/settlement"
                //     })
                // }
            },
            async connect() {
                //初始化合约
                let provider = window.ethereum || window.web3.currentProvider;
                this.web3 = new this.Web3(provider);
                let myContract = await new this.web3.eth.Contract(
                    avatar.ABI,
                    this.configCon.contract_address
                );
                this.initData(myContract);
                this.connectWallet(myContract);
                // await this.web3.eth.getAccounts(async (err, accs) => {
                //     console.log("accs", accs);
                //     await this.setData({username: accs[0]});
                //     await this.getCode();
                //     /***
                //      * 检查当前账户是否加入成员
                //      * start
                //      * **/
                //     let isJoin = await myContract.methods.balanceOf(this.username).call();
                //     this.setData({isJoin});
                //     /***
                //      * 检查当前账户是否加入成员
                //      * end
                //      * **/
                // });
            },
            async connectWallet() {
                let provider = window.ethereum || window.web3.currentProvider;
                if (provider.chainId == "0x38") {
                    await window.ethereum.request({method: 'eth_requestAccounts'});
                } else {
                    this.$message({
                        message: "Please switch to BSC mainnet",
                        type: "info",
                    });
                    await window.ethereum.request({
                        method: "wallet_switchEthereumChain",
                        params: [{chainId: '0x38'}],// 主网id：0x38、测试网id：0x61
                    });
                }
                await this.setData({username: window.ethereum.selectedAddress});
                await this.getCode();
                this.getisJsin();
            },
            async getisJsin() {
                let provider = window.ethereum || window.web3.currentProvider;
                this.web3 = new this.Web3(provider);
                let myContract = await new this.web3.eth.Contract(
                    avatar.ABI,
                    this.configCon.contract_address
                );
                let isJoin = await myContract.methods.balanceOf(this.username).call();
                this.setData({isJoin});
            },
            async initData(myContract) {
                let config = await myContract.methods.configView().call();
                jsonToUnderline(config);

                function jsonToUnderline(obj) {
                    Object.keys(obj).forEach((key) => {
                        let newKey = underline2Hump(key)
                        if (newKey !== key) {
                            obj[newKey] = obj[key]
                            delete obj[key]
                        }
                    });
                    return obj;
                }

                function underline2Hump(s) {
                    return s.replace(/_(\w)/g, function (all, letter) {
                        return letter;
                    })
                }

                this.setData({auditInfo: {...config}});
            },
            async getCode() {
                let loading = this.$loading({
                    lock: true,
                    text: "Log in...",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                let provider = Init();
                let web3 = new this.Web3(provider);
                let res = await this.api.auth().getCode({address: this.username});
                if (res.data.code == 200) {
                    const loginCode = res.data.data.code;
                    if (!this.signCode) {
                        loading.text = 'Please authorize in wallet';
                        const signCode = await web3.eth.personal.sign(web3.utils.utf8ToHex(loginCode), this.username);
                        this.setData({signCode});
                    }
                    let loginRes = await this.login({
                        address: this.username, code: loginCode, sign: this.signCode
                    });
                    if (loginRes.data.code == 200) {
                        //登录成功
                        if (this.logoutUrl) {
                            this.$router.push(this.logoutUrl);
                            this.setData({logoutUrl: ""});
                        }
                        loading.close();
                        this.$message({
                            message: "login successful",
                            type: "success",
                        });
                    }
                }
            },
            toHome() {
                this.$router.push("/");
            },
            handleClose(done) { //关闭
                done();
            },
            toPath(item) {
                if (!this.userInfo) {
                    this.$message({
                        message: "Please connect the wallet to operate",
                        type: "info",
                    });
                    return;
                }
                if (item.link) {
                    window.open(item.path);
                } else {
                    this.$router.push(item.path);
                }
                this.drawer = false;
            },
        }
    }
</script>

<style lang="scss" scoped>
    .el-dropdown-menu__item {
        min-width: 80px;
        padding: 0 10px;
        color: #212833;

        .iconfont {
            margin-right: 10px;
            font-size: 12px;
        }
    }

    .header {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 66;
        width: 100%;
        height: 63px;
        background-color: #ffffff;
        display: flex;
        justify-content: center;

        .header-content {
            width: 1200px;
            height: 63px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .header-left {
                display: flex;
                align-items: center;

                .logo {
                    width: 189px;
                    cursor: pointer;

                    img {
                        width: 100%;
                    }
                }
            }

            .haeder-right {
                display: flex;
                align-items: center;

                .nav-item {
                    position: relative;
                    margin-right: 50px;
                    display: block;
                    font-size: 16px;
                    text-decoration: none;
                    color: #000000;
                    cursor: pointer;
                    text-transform: uppercase;
                }

                .nav-item:hover {
                    color: #3A56FF;
                }

                .link-wallet {
                    width: 68px;
                    height: 48px;
                    background: #3A56FF;
                    border-radius: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #ffffff;
                    cursor: pointer;

                    .iconfont {
                        margin-right: 5px;
                        font-size: 25px;
                    }
                }

                .user-wallet {
                    width: auto;
                    padding: 0 12px;
                    border-radius: 4px;
                    @extend .link-wallet;
                    font-size: 18px;
                }

                .more {
                    position: relative;
                    margin-right: 36px;
                    cursor: pointer;

                    .iconfont {
                        font-weight: bold;
                    }

                    .notify {
                        position: absolute;
                        right: -8px;
                        top: -6px;
                        width: 16px;
                        height: 12px;
                        background: #D0050D;
                        border-radius: 7px;
                        border: 1px solid #FFFFFF;
                        text-align: center;
                        line-height: 12px;
                        color: #FFFFFF;
                        font-size: 10px;
                    }
                }
            }
        }
    }

    .avatar-container {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        padding-left: 32px;
        flex-direction: column;
        justify-content: center;


        .logo {
            width: 162px;
            height: 33px;

            img {
                width: 100%;
            }
        }

        .link-wallet {
            margin: 48px 0;
            width: 235px;
            height: 68px;
            background: #3A56FF;
            border-radius: 3px;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            padding: 0 24px;
            color: #FFFFFF;
            font-size: 20px;
            cursor: pointer;

            .iconfont {
                margin-right: 15px;
                font-size: 24px;
            }
        }

        .line {
            width: 236px;
            height: 1px;
            background-color: #EBEDFC;
        }
    }

    .nav-wrap {
        margin-top: 40px;
        width: 100%;

        .nav-item {
            position: relative;
            display: block;
            width: 100%;
            height: 68px;
            line-height: 68px;
            box-sizing: border-box;
            padding: 0 32px;
            font-size: 18px;
            color: #0F0F0F;
            font-weight: 600;
            text-decoration: none;
            cursor: pointer;
            text-transform: uppercase;

            .border {
                display: none;
                position: absolute;
                left: 0;
                top: 50%;
                margin-top: -10px;
                width: 2px;
                height: 20px;
                background-color: #3A56FF;
            }

            .prompt {
                position: absolute;
                top: 50%;
                right: 33px;
                margin-top: -3px;
                border-radius: 50%;
                width: 6px;
                height: 6px;
                background: #D0050D;
            }
        }

        .nav-item:hover .border {
            display: block;
        }
    }

    .noFixed {
        position: static;
        z-index: auto;
    }

    @media screen and (max-width: 1200px) {
        .header .header-content .haeder-right .nav-item {
            display: none;
        }
        .header .header-content {
            width: 100%;
            box-sizing: border-box;
            padding: 0 10px;
        }
    }

    @media screen and (max-width: 750px) {
        .header .header-content .haeder-right .link-wallet {
            display: none;
        }
    }
</style>
<style lang="scss">
    .header {
        .el-drawer {
            width: 299px !important;
        }
    }
</style>
