<template>
    <div id="app">
        <my-header v-if="isUpdate"></my-header>
        <keep-alive>
            <router-view v-if="$route.meta&&$route.meta.keepAlive&&isUpdate" style="margin: 63px 0 0 0;"/>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive&&isUpdate" style="margin: 63px 0 0 0;"/>
        <my-footer></my-footer>
    </div>
</template>
<script>
    import myHeader from "./components/header";
    import myFooter from "./components/footer";
    import {mapActions, mapMutations, mapState} from "vuex";
    import {Init} from "./plugins/web3";
    import avatar from "./ABI/avatar.json";

    export default {
        name: "app",
        data() {
            return {
                isUpdate: true,
            }
        },
        components: {
            myHeader,
            myFooter
        },
        mounted() {
            this.listening_web3();
        },
        computed: {
            ...mapState('user', ['signCode']),
        },
        methods: {
            ...mapMutations('user', ['logout', 'setData']),
            ...mapActions('user', ['login']),
            listening_web3() {  //监听切换账户
                window.ethereum.on("accountsChanged", (accounts) => {
                    this.logout();
                    this.setData({username: accounts[0]});
                    this.getCode();
                });
                window.ethereum.on('chainChanged', (chainId) => {
                    console.log("chainId", chainId);
                    // Handle the new chain.
                    // Correctly handling chain changes can be complicated.
                    // We recommend reloading the page unless you have good reason not to.
                    // window.location.reload();
                });
            },
            async getCode() {
                let loading = this.$loading({
                    lock: true,
                    text: "Log in...",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                let provider = Init();
                let web3 = new this.Web3(provider);
                /***
                 * 检查当前账户是否加入成员
                 * start
                 * **/
                let myContract = await new web3.eth.Contract(
                    avatar.ABI,
                    this.configCon.contract_address
                );
                this.initData(myContract);
                let isJoin = await myContract.methods.balanceOf(this.username).call();
                this.setData({isJoin});
                /***
                 * end
                 * **/
                let res = await this.api.auth().getCode({address: this.username});
                if (res.data.code == 200) {
                    const loginCode = res.data.data.code;
                    if (!this.signCode) {
                        loading.text = 'Please authorize in wallet';
                        const signCode = await web3.eth.personal.sign(web3.utils.utf8ToHex(loginCode), this.username);
                        this.setData({signCode});
                    }
                    let loginRes = await this.login({
                        address: this.username, code: loginCode, sign: this.signCode
                    });
                    if (loginRes.data.code == 200) {
                        this.setData({username: window.ethereum.selectedAddress});
                        this.isUpdate = false;
                        this.$nextTick(() => {
                            this.isUpdate = true;
                        });
                        //登录成功
                        loading.close();
                        this.$message({
                            message: "login successful",
                            type: "success",
                        });
                    }
                }
            },
            async initData(myContract) {
                let config = await myContract.methods.configView().call();
                jsonToUnderline(config);

                function jsonToUnderline(obj) {
                    Object.keys(obj).forEach((key) => {
                        let newKey = underline2Hump(key)
                        if (newKey !== key) {
                            obj[newKey] = obj[key]
                            delete obj[key]
                        }
                    });
                    return obj;
                }

                function underline2Hump(s) {
                    return s.replace(/_(\w)/g, function (all, letter) {
                        return letter;
                    })
                }

                this.setData({auditInfo: {...config}});
            },
        }
    }
</script>
<style lang="scss">
    #app {
        width: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

    }

    .public-header {
        width: 100%;

        .title {
            margin-top: 100px;
            width: 100%;
            font-size: 36px;
            font-weight: 600;
            color: #0F0F0F;
            line-height: 50px;
            text-align: center;
        }
    }
</style>
