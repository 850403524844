import Vue from 'vue';
import ElementUI, {Message} from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import './css/element-variables.scss';

Vue.use(ElementUI);
import animated from 'animate.css' // npm install animate.css --save安装，再引入
Vue.use(animated);

import Web3 from 'web3'

Vue.prototype.Web3 = Web3;

Vue.prototype.copyUrl = function (data, text) {
    let url = data;
    let oInput = document.createElement('input');
    oInput.value = url;
    document.body.appendChild(oInput);
    oInput.select(); // 选择对象;
    document.execCommand("Copy"); // 执行浏览器复制命令
    Message({
        message: text,
        type: "success"
    });
    oInput.remove();
}
Vue.prototype.walletSub = function (str, number) {
    let startStr = str.substr(0, number);
    let endStr = str.substr(str.length - number, number);
    return startStr + "....." + endStr;
}

Vue.prototype.underline2Hump = function (s) {
    return s.replace(/_(\w)/g, function(all, letter) {
        return letter;
    })
}
