let {ethereum} = window;

import {Message} from "element-ui";

/**
 * 初始化
 * @param {Object} provider 返回钱包账户
 */
export function Init() {
    let provider = null;
    //判断用户是否安装MetaMask钱包插件
    if (!ethereum && !window.web3) {
        //没安装MetaMask钱包进行弹框提示
        Message({
            message: 'Please operate after installing the wallet',
            type: "info"
        });
    }
    if (ethereum) {
        provider = ethereum;
        // 请求连接
        ethereum.enable();
        // connect();
    } else {
        provider = window.web3.currentProvider; //当前钱包账户
        connect();
        if (provider.chainId != '0x1') {
            Message({
                message: 'Please link the ETH chain',
                type: "info"
            });
        }
    }

    return provider;
}

let connect = async () => { //定位到xx链
    try {
        //switch xx链
        let accountInfo = await ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: '0x3'}],// 主网id：0x1、测试网id：0x3
        });
        console.log("accountInfo", accountInfo);
    } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
            const data = [{
                chainId: '0x3',
                chainName: 'Ethereum',
                nativeCurrency:
                    {
                        name: 'ETH',
                        symbol: 'ETH',
                        decimals: 18
                    },
                rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
                blockExplorerUrls: ['https://etherscan.io'],
            }]
            try {
                await ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: data,
                });
            } catch (addError) {
                Message({
                    message: addError,
                    type: "error"
                });
            }
        } else {
            Message({
                message: switchError.message,
                type: "error"
            });
        }
        // handle other "switch" errors
    }
}
