<template>
    <div class="home">
        <div class="home-header">
            <div class="header-left">
                <p class="title">Venture Capital DAO</p>
                <p class="discription">
                    100% own by DAO, run by DAO<br/>
                    support avatars, builder of crypto & web3
                </p>
                <div class="join-btn" @click="toPath({path:'/Apply'})">
                    join us
                </div>
            </div>
            <div class="header-right">
                <img src="../assets/home-header-rightbg.jpg" class="img">
            </div>
        </div>
        <div class="container animate__animated animate__slideInUp">
            <el-image
                    :src="homeContainer"
                    class="image"
                    fit="contain"
            ></el-image>
        </div>
        <!--        <div class="avatar-info">-->
        <!--            <div class="info-container">-->
        <!--                <div class="container">-->
        <!--                    AVATARDAO is a Venture Capital DAO organization, aim to supporting bold entrepreneurs better-->
        <!--                    building-->
        <!--                    Crypto & WEB3.-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
    </div>
</template>

<script>
    // @ is an alias to /src
    import homeContainer from "../assets/home-container.jpg";
    import {Init} from "../plugins/web3";
    import avatar from "../ABI/avatar.json";
    import {mapState, mapActions} from "vuex";

    export default {
        name: 'Home',
        data() {
            return {
                homeContainer: homeContainer
            }
        },
        computed: {
            ...mapState('user', ['userInfo'])
        },
        mounted() {
            this.configInfo();
        },
        methods: {
            ...mapActions('user', ['configInfo']),
            toPath(item) {
                if (!this.userInfo) {
                    this.$message({
                        message: "Please connect the wallet to operate",
                        type: "info",
                    });
                    return;
                }
                if (item.link) {
                    window.open(item.path);
                } else {
                    this.$router.push(item.path);
                }
            },
            async callCon() {
                //初始化合约
                let provider = Init();
                this.web3 = new this.Web3(provider);
                this.myContract = await new this.web3.eth.Contract(
                    avatar.ABI,
                    this.configCon.contract_address
                );
                console.log("myContract", this.myContract);
                let result = await this.myContract.methods.operateAddress().call();
                console.log("result", result);
            },

        }
    }
</script>

<style lang="scss" scoped>

    @font-face {
        font-family: AvatarTit;
        src: url('../plugins/css/AlibabaSans-Black.otf') format("truetype");
    }

    .home-header {
        width: 1200px;
        margin: 100px auto 20px auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .header-left {
            width: 557px;

            .title {
                font-family: AvatarTit;
                margin-bottom: 58px;
                color: #110D0A;
                font-size: 44px;
            }

            .discription {
                margin: 50px 0;
                line-height: 44px;
                font-size: 20px;
                color: #454350;
            }

            .join-btn {
                width: 160px;
                height: 50px;
                background: linear-gradient(349deg, #6236D2 0%, #3A56FF 100%);
                box-shadow: 0px 72px 32px 0px rgba(27, 117, 240, 0.11);
                color: #FFFFFF;
                font-size: 20px;
                font-weight: 500;
                border-radius: 5px;
                text-align: center;
                line-height: 50px;
                box-sizing: border-box;
                cursor: pointer;
                text-transform: uppercase;
            }
        }

        .header-right {
            width: 483px;
            height: 552px;
            animation: dong 3s infinite;

            .img {
                width: 100%;
                height: 100%;
            }
        }
    }

    @keyframes dong {
        0% {
            transform: translate(0px, 0px);
        }
        50% {
            transform: translate(0px, -30px);
        }
        100% {
            transform: translate(0px, 0px);
        }
    }

    .container {
        width: 100%;
        height: 677px;

        .image {
            width: 100%;
            height: 100%;
        }
    }

    .avatar-info {
        margin: 50px auto;
        width: 1200px;
        height: 242px;
        background-color: #3A56FF;
        padding-top: 12px;
        display: flex;
        justify-content: flex-end;

        .info-container {
            width: 1188px;
            height: 242px;
            border: 1px solid #3A56FF;
            background-color: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;

            .container {
                width: 1086px;
                height: 242px;
                line-height: 60px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 24px;
                color: #111C5D;
            }
        }
    }

    @media screen and (max-width: 1440px) {
        .home-header {
            width: 100%;
            box-sizing: border-box;
            padding: 0 100px;
        }
    }

    @media screen and (max-width: 1200px) {
        .home-header {
            width: 100%;
            justify-content: center;

            .header-left {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            .header-right {
                display: none;
            }
        }
        .avatar-info {
            width: 85%;

            .info-container {
                width: 98%;

                .container {
                    width: 80%;
                    font-size: 18px;
                }
            }
        }
        .home-header .header-left .discription {
            text-align: center;
        }
    }

    @media screen and (max-width: 750px) {
        .home-header {
            padding: 0 20px;
        }
        .home-header .header-left .title {
            margin-bottom: 10px;
            font-size: 40px;
        }
        .home-header .header-left .discription {
            margin: 10px 0;
            font-size: 18px;
        }
        .home-header .header-left .join-btn {
            margin-top: 20px;
            width: 300px;
            height: 60px;
            line-height: 60px;
        }
        .avatar-info {
            width: 90%;
        }
        .avatar-info .info-container .container {
            width: 90%;
        }
        .container {
            height: 300px;
        }
    }
</style>
<style lang="scss">
    @media screen and (max-width: 1200px) {
        .container {
            .el-image__inner {
                object-fit: cover !important;
            }
        }
    }

</style>
