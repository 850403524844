import axios from "axios";
import {Message, Loading} from "element-ui";
import stores from "../store";
import router from "../router";

let baseURL;
if (process.env.NODE_ENV === 'development') {
    console.log('开发环境');
    baseURL = 'https://api.avatardao.vc';
} else {
    console.log('生产环境');
    baseURL = 'https://api.avatardao.vc';
}
const instance = axios.create({
    baseURL
});
let num = 0;
//请求拦截器
instance.interceptors.request.use((config) => {
    // config.headers.common['Accept'] = "application/json";
    return config;
}, error => {
    return Promise.reject(error)
});
// 响应拦截器
instance.interceptors.response.use((response) => {
    if (response.data) {
        if (response.data.code == -200) {
            num++;
            if (num == 1) {
                Message({
                    message: response.data.message,
                    type: "error"
                });
                num = 0;
            }
        }
        if (response.data.code != 200) {
            num++;
            if (num == 1) {
                Loading.close();
                Message({
                    message: response.data.message,
                    type: "error"
                });
                num = 0;
            }
        }
        if (response.data.code == 401) {
            num++;
            if (num == 1) {
                stores.commit('user/logout'); //退出登录
                router.push('/');
                num = 0;
            }
        }
    }
    return response;
}, (error) => {
    if (error.response) {
        switch (error.response.status) {
            case 401: // 这里写清除token的代码
                stores.commit('user/logout'); //退出登录
                router.push('/');
                break;
            case 422:
                Message.error({
                    message: stores.state.user.lang == 'zh' ? '参数错误' : 'Parameter Error',
                    type: "error"
                });
                break;
            case 500:
                Message.error({
                    message: stores.state.user.lang == 'zh' ? '参数错误' : 'System Error',
                    type: "error"
                });
                break;
            case 429:
                Message.error({
                    message: stores.state.user.lang == 'zh' ? '请求频繁，稍后再试' : 'Frequent requests, try again later',
                    type: "error"
                });
                break;
        }
    }
    return Promise.reject(error)
})

export default instance;
