import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//element-ui
import "./plugins/element";
//moment
import "./plugins/moment";
//api
import api from "./api/index";

Vue.config.productionTip = false;

Vue.prototype.api = api;//api接口入口


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
