import moment from 'moment';
import Vue from "vue";
Vue.filter('datefmt',function (input,fmtstring) {//当input为时间戳，需转为Number类型
    if(input){
        return moment(input).format(fmtstring);
    }else{
        return "";
    }
});

Vue.filter('filterNum', function(value, n) {
    var f = Math.round(value*Math.pow(10,n))/Math.pow(10,n);
    var s = f.toString();
    var rs = s.indexOf('.');
    if (rs < 0) {
        s += '.';
    }
    for(var i = s.length - s.indexOf('.'); i <= n; i++){
        s += "0";
    }
    return s;
});

/////全局配置时区问题
Vue.prototype.formatTime = function (t) {
    //t传入的时间参数,utc传入的时区参数
    if (!t) return;
    //获取本地时间
    let d = new Date();
    //获得本地时区 (单位是小时)
    let timezone = d.getTimezoneOffset() / 60;
    t = t.replace(/-/g, "/"); //兼容苹果电脑
    //格式化传入时间
    let time = new Date(t);
    //转换传入时间为本地时间              设置小时  ----- ( 传入时间的小时数 - 本地时区转化成的小时数  )
    time.setHours(time.getHours() - (timezone));

    //输出时间
    let yy = time.getFullYear();     ///获取年
    let MM = time.getMonth() + 1;    ///获取月
    MM = MM < 10 ? '0' + MM : MM;    ///字符串拼接月个位数补0
    let dd = time.getDate();         ///获取日
    dd = dd < 10 ? '0' + dd : dd;	 ///字符串拼接日个位数补0
    let hh = time.getHours();		 ///获取小时
    hh = hh < 10 ? '0' + hh : hh;	 ///字符串拼接小时个位数补0
    let mm = time.getMinutes();		 ///获取分钟
    mm = mm < 10 ? '0' + mm : mm;	 ///字符串拼接分钟个位数补0
    let ss = time.getSeconds();		 ///获取秒
    ss = ss < 10 ? '0' + ss : ss;	 ///字符串拼接秒个位数补0
    let date = yy + '-' + MM + '-' + dd + ' ' + hh + ':' + mm + ':' + ss;
    return date;
}
